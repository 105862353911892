@charset "UTF-8";

/*scss generated for en*/
@media (max-width: 1023px) {
  .home-wrapp header .offcanvas {
    background-color: #00b577;
  }
}
.home-wrapp .subtitle {
  font-size: 16px;
}

@media (min-width: 992px) {
  .home-wrapp .subtitle {
    font-size: 18px;
  }
}

.home-wrapp .btn {
  height: 60px;
  line-height: 60px;
  border: 0px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: ease all 0.3s;
  transition: ease all 0.3s;
  -webkit-box-shadow: 0px 0px 0px black;
  box-shadow: 0px 0px 0px black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  max-width: 250px;
  width: 100%;
  background-color: #ffffff;
  padding: 0 20px 0 30px;
  overflow: hidden;
  position: relative;
}

.home-wrapp .btn .text,
.home-wrapp .btn .icon {
  z-index: 2;
  position: relative;
}

.home-wrapp .btn .icon {
  background-image: url('./Icons/arrow-forward.svg');
  height: 30px;
  width: 30px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.home-wrapp .btn.btn-green {
  background-color: #00b577;
}

.home-wrapp .btn.btn-green .text {
  color: #ffffff;
}

.home-wrapp html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

.home-wrapp body {
  background: #ffffff;
  font-family: 'FS-Albert-Pro', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.home-wrapp body #page {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in all;
  transition: 0.3s ease-in all;
}

.home-wrapp body.is-ready #page {
  opacity: 1;
  visibility: visible;
}

.home-wrapp main {
  height: 100%;
  background-color: #ffffff;
  overflow: hidden;
}

.home-wrapp #page {
  height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.home-wrapp header {
  -webkit-transition: ease-in all 0.3s;
  transition: ease-in all 0.3s;
}

.home-wrapp header.sticky .navbar {
  background-color: #233746;
  padding: 10px 0;
}

.home-wrapp header.sticky .navbar .navbar-brand img.tii {
  width: 120px;
}

.home-wrapp header.sticky .navbar .navbar-brand img.derc {
  width: 100px;
}

.home-wrapp header .navbar {
  padding: 15px 0;
  -webkit-transition: ease-in all 0.3s;
  transition: ease-in all 0.3s;
}

@media (min-width: 768px) {
  .home-wrapp header .navbar {
    padding: 20px 0;
  }
}

.home-wrapp header .navbar .navbar-brand img {
  -webkit-transition: ease-in all 0.3s;
  transition: ease-in all 0.3s;
}

.home-wrapp header .navbar .navbar-brand img.tii {
  width: 120px;
}

@media (min-width: 768px) {
  .home-wrapp header .navbar .navbar-brand img.tii {
    width: 160px;
  }
}

.home-wrapp header .navbar .navbar-brand img.derc {
  width: 100px;
  margin-left: 20px;
}

@media (min-width: 768px) {
  .home-wrapp header .navbar .navbar-brand img.derc {
    width: 130px;
    margin-left: 40px;
  }
}

.home-wrapp header .navbar-nav .nav-item {
  margin-left: 20px;
}

@media (min-width: 992px) {
  .home-wrapp header .navbar-nav .nav-item.dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-right: 20px;
  }
}

.home-wrapp header .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  background: none;
  border: none;
}

@media (min-width: 992px) {
  .home-wrapp header .navbar-nav .nav-item .nav-link {
    font-size: 18px;
  }
}

.home-wrapp header .navbar-nav .nav-item .dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 992px) {
  .home-wrapp header .navbar-nav .nav-item .dropdown-toggle {
    position: static;
    top: auto;
    right: auto;
  }
}

.home-wrapp header .navbar-nav .nav-item .dropdown-toggle::after {
  border: 0px;
  content: '';
  color: #ffffff;
  color: #7eddd3;
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 0;
}

.home-wrapp header .navbar-nav .nav-item .dropdown-menu {
  border-radius: 0px;
  background-color: #00b577;
  right: 0;
  left: auto;
}

.home-wrapp header .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  font-weight: 500;
}

.home-wrapp header .navbar-toggler {
  border: 0px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
}

.home-wrapp header .navbar-toggler span {
  color: #ffffff;
  font-size: 40px;
}

.home-wrapp header .offcanvas .offcanvas-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.home-wrapp header .offcanvas .offcanvas-header .offcanvas-title {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  padding-left: 20px;
}

.home-wrapp header .offcanvas .offcanvas-header .offcanvas-title img {
  max-width: 100px;
  width: 100%;
}

.home-wrapp header .offcanvas .btn-close {
  background-image: none;
  margin-left: auto;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
}

.home-wrapp header .offcanvas .btn-close span {
  font-size: 40px;
  color: #ffffff;
}

.home-wrapp footer a {
  color: #00b577;
  text-decoration: none;
  font-weight: bold;
}

@media (min-width: 992px) {
  .home-wrapp footer p {
    margin-bottom: 0px;
  }
}

.home-wrapp .h-100 {
  height: 100%;
}

.home-wrapp p {
  line-height: 1.5;
  opacity: 0.75;
}

.home-wrapp .o-75 {
  opacity: 0.75;
}

.home-wrapp body {
  color: #233746;
}

.home-wrapp .bold {
  font-weight: bold;
}

.home-wrapp .bg-white {
  background-color: #ffffff;
}

.home-wrapp .bg-light {
  background-color: #efefef !important;
}

.home-wrapp .bg-green {
  background-color: #00b577;
}

.home-wrapp .bg-gray {
  background-color: #233746;
}

.home-wrapp .section-content,
.home-wrapp .section-title {
  position: relative;
  z-index: 5;
}

.home-wrapp .mask {
  visibility: hidden;
  position: relative;
  overflow: hidden;
}

.home-wrapp .mask img {
  width: 100%;
  -webkit-transform-origin: left;
  transform-origin: left;
  -o-object-fit: cover;
  object-fit: cover;
}

.home-wrapp .main-banner {
  position: relative;
  height: 100vh;
  overflow: hidden;
  min-height: 700px;
}

.home-wrapp .main-banner .content-text {
  position: relative;
  z-index: 2;
}

.home-wrapp .main-banner .content-text .title-content {
  position: relative;
}

.home-wrapp .main-banner .content-text .title {
  font-size: 40px;
  line-height: 1.2;
  position: relative;
  z-index: 4;
  padding-top: 40px;
}

@media (min-width: 768px) {
  .home-wrapp .main-banner .content-text .title {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .home-wrapp .main-banner .content-text .title {
    font-size: 70px;
    line-height: 1.1;
    padding-top: 50px;
  }
}

@media (min-width: 1200px) {
  .home-wrapp .main-banner .content-text .title {
    font-size: 85px;
    line-height: 1;
    padding-top: 80px;
  }
}

.home-wrapp .main-banner .content-text .lottiefile {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 150px;
}

@media (min-width: 992px) {
  .home-wrapp .main-banner .content-text .lottiefile {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .home-wrapp .main-banner .content-text .lottiefile {
    top: 70px;
  }
}

@media (min-width: 1200px) {
  .home-wrapp .main-banner .content-text .lottiefile {
    top: 80px;
    max-width: 400px;
  }
}

.home-wrapp .main-banner .content-text .noor-wrap {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .home-wrapp .main-banner .content-text .noor-wrap {
    margin-bottom: 60px;
  }
}

.home-wrapp .main-banner .content-text .noor-wrap .noor-logo {
  width: 100%;
  max-width: 200px;
}

@media (min-width: 992px) {
  .home-wrapp .main-banner .content-text .noor-wrap .noor-logo {
    max-width: 270px;
  }
}

.home-wrapp .main-banner .content-text .noor-wrap .noor-logo img {
  width: 100%;
}

.home-wrapp .main-banner .content-text .noor-wrap .noor-desc {
  max-width: 400px;
  width: 100%;
}

.home-wrapp .main-banner .content-text .noor-wrap .noor-desc .subtitle {
  font-size: 22px;
  line-height: 1.3;
}

.home-wrapp .main-banner .bg-image {
  position: absolute;
  height: 100%;
  width: 100%;
  left: -1px;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  bottom: 0px;
  z-index: 0;
  pointer-events: none;
}

.home-wrapp .main-banner .bg-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.home-wrapp .main-banner .scroll-down {
  position: absolute;
  bottom: 35px;
  right: 0px;
  left: auto;
  width: auto;
}

@media (min-width: 992px) {
  .home-wrapp .main-banner .scroll-down {
    bottom: 60px;
  }
}

.home-wrapp .typrewriter {
  padding: 80px 0;
  text-align: center;
  background-color: rgba(164, 188, 194, 0.1);
}

.home-wrapp .typrewriter .h2 {
  position: relative;
  z-index: 2;
}

.home-wrapp .typrewriter .typewrite {
  position: relative;
  z-index: 2;
}

.home-wrapp .typrewriter .typewrite .wrap {
  border-right: 0.08em solid #00b577;
}

.home-wrapp .typrewriter .dropdown-typewriter {
  z-index: 3;
  position: relative;
}

.home-wrapp .typrewriter .dropdown-typewriter .btn {
  color: #ffffff;
}

.home-wrapp .typrewriter .dropdown-typewriter .btn:after {
  background-image: url('./Icons/caret-down.svg');
  height: 30px;
  width: 30px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  border: 0px;
  margin-left: 30px;
}

.home-wrapp .typrewriter .dropdown-typewriter .dropdown-menu {
  background-color: #233746;
  width: 100%;
}

.home-wrapp .typrewriter .lottie-wrap {
  position: relative;
  -webkit-transform: translateY(88px);
  transform: translateY(88px);
}

.home-wrapp .typrewriter .lottie-wrap .lottiefile {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  max-width: 100px;
  pointer-events: none;
  z-index: 0;
}

@media (min-width: 992px) {
  .home-wrapp .typrewriter .lottie-wrap .lottiefile {
    max-width: 200px;
  }
}

@media (min-width: 1200px) {
  .home-wrapp .typrewriter .lottie-wrap .lottiefile {
    max-width: 220px;
  }
}

@media (min-width: 1400px) {
  .home-wrapp .typrewriter .lottie-wrap .lottiefile {
    max-width: 300px;
  }
}

.home-wrapp .overview {
  padding: 80px 0;
  position: relative;
}

.home-wrapp .overview .section-content {
  position: relative;
  z-index: 2;
}

.home-wrapp .overview .lottie-wrap {
  position: relative;
  -webkit-transform: translateY(-88px);
  transform: translateY(-88px);
}

.home-wrapp .overview .lottie-wrap .lottiefile {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  right: 0;
  width: 100%;
  max-width: 150px;
  pointer-events: none;
  z-index: 0;
}

@media (min-width: 992px) {
  .home-wrapp .overview .lottie-wrap .lottiefile {
    max-width: 250px;
  }
}

@media (min-width: 1200px) {
  .home-wrapp .overview .lottie-wrap .lottiefile {
    max-width: 300px;
  }
}

@media (min-width: 1400px) {
  .home-wrapp .overview .lottie-wrap .lottiefile {
    max-width: 370px;
    right: 5%;
  }
}

.home-wrapp .overview .highlight {
  border-radius: 5px;
}

.home-wrapp .proposal {
  position: relative;
  padding: 80px 0;
  background-color: rgba(164, 188, 194, 0.1);
}

.home-wrapp .proposal .lottie-wrap {
  position: relative;
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.home-wrapp .proposal .lottie-wrap .lottiefile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100px;
  pointer-events: none;
  z-index: 0;
}

@media (min-width: 992px) {
  .home-wrapp .proposal .lottie-wrap .lottiefile {
    max-width: 130px;
  }
}

@media (min-width: 1200px) {
  .home-wrapp .proposal .lottie-wrap .lottiefile {
    max-width: 150px;
  }
}

@media (min-width: 1400px) {
  .home-wrapp .proposal .lottie-wrap .lottiefile {
    max-width: 200px;
  }
}

.home-wrapp .proposal .box-stat {
  border-radius: 5px;
  position: relative;
  width: 100%;
  padding-top: 50%;
  /* 4:3 Aspect Ratio */
}

@media (min-width: 992px) {
  .home-wrapp .proposal .box-stat {
    padding-top: 75%;
    /* 4:3 Aspect Ratio */
  }
}

.home-wrapp .proposal .box-stat .text-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  color: white;
}

.home-wrapp .purpose {
  position: relative;
  padding: 80px 0;
}

.home-wrapp .purpose .lottie-wrap {
  position: relative;
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.home-wrapp .purpose .lottie-wrap .lottiefile {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-1deg);
  transform: translate(-50%, -50%) rotate(-1deg);
  left: 50%;
  width: 100%;
  max-width: 120px;
  pointer-events: none;
  z-index: 0;
  right: 0;
  margin-top: 0.4em;
}

@media (min-width: 992px) {
  .home-wrapp .purpose .lottie-wrap .lottiefile {
    max-width: 220px;
    left: auto;
    -webkit-transform: translateY(-50%) rotate(-1deg);
    transform: translateY(-50%) rotate(-1deg);
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .home-wrapp .purpose .lottie-wrap .lottiefile {
    max-width: 300px;
    top: 0.4em;
  }
}

@media (min-width: 1400px) {
  .home-wrapp .purpose .lottie-wrap .lottiefile {
    max-width: 370px;
    right: 5%;
    top: 0.48em;
  }
}

.home-wrapp .name {
  position: relative;
  padding: 80px 0 0;
  background-color: rgba(164, 188, 194, 0.1);
}

@media (min-width: 768px) {
  .home-wrapp .name {
    padding: 80px 0;
  }
}

.home-wrapp .name .lottie-wrap {
  position: relative;
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.home-wrapp .name .lottie-wrap .lottiefile {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  right: 0;
  left: auto;
  width: 100%;
  max-width: 150px;
  pointer-events: none;
  z-index: 0;
}

@media (min-width: 992px) {
  .home-wrapp .name .lottie-wrap .lottiefile {
    max-width: 200px;
    left: auto;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 1400px) {
  .home-wrapp .name .lottie-wrap .lottiefile {
    max-width: 250px;
  }
}

@media (min-width: 1400px) {
  .home-wrapp .name .lottie-wrap .lottiefile {
    max-width: 300px;
  }
}

.home-wrapp .name .f-image {
  position: relative;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .home-wrapp .name .f-image {
    margin-top: 0px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.home-wrapp .name .f-image .content-image {
  position: relative;
  min-height: 350px;
}

.home-wrapp .name .f-image .content-image .mask {
  min-height: 350px !important;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 0px;
  position: absolute;
  height: 100%;
}

@media (min-width: 768px) {
  .home-wrapp .name .f-image .content-image .mask {
    min-height: 550px !important;
    height: 100%;
  }
}

.home-wrapp .name .f-image .content-image img {
  padding: 50px;
  height: 350px;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transform: translateX(-50%) scale(1);
  transform: translateX(-50%) scale(1);
  position: absolute;
  left: 50%;
}

@media (min-width: 768px) {
  .home-wrapp .name .f-image .content-image img {
    min-height: 550px;
    height: 100%;
    width: 100%;
    padding: 100px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.home-wrapp .dropdown.dropdown-typewriter.no-arrow .btn.btn-green {
  background-color: #00b577;
}

.home-wrapp .link-inside .btn::after {
  display: none !important;
}

@media (min-width: 992px) {
  .home-wrapp header .navbar-toggler {
    display: none;
  }
  .home-wrapp header .navbar-toggler span {
    font-family: 'Material Icons Outlined' !important;
  }
}

@media (max-width: 992px) {
  .home-wrapp header .navbar-toggler span,
  .home-wrapp header .offcanvas .btn-close span {
    font-family: 'Material Icons Outlined' !important;
  }
}

/** AR CSS **/

@media (max-width: 1023px) {
  html:lang(ar) .home-wrapp header .offcanvas {
    background-color: #00b577;
  }
}
html:lang(ar) .home-wrapp {
  direction: rtl;
}

html:lang(ar) .home-wrapp .h1,
html:lang(ar) .home-wrapp h1 {
  font-size: 60px;
}

html:lang(ar) .home-wrapp .h2,
html:lang(ar) .home-wrapp h2 {
  font-size: 30px;
  letter-spacing: -1px;
}

@media (min-width: 992px) {
  html:lang(ar) .home-wrapp .h2,
  html:lang(ar) .home-wrapp h2 {
    font-size: 42px;
  }
}

html:lang(ar) .home-wrapp .h3,
html:lang(ar) .home-wrapp h3 {
  font-size: 28px;
}

html:lang(ar) .home-wrapp .h4,
html:lang(ar) .home-wrapp h4 {
  font-size: 24px;
}

html:lang(ar) .home-wrapp .h5,
html:lang(ar) .home-wrapp h5 {
  font-size: 22px;
  line-height: 1.5;
}

html:lang(ar) .home-wrapp .h6,
html:lang(ar) .home-wrapp h6 {
  font-size: 18px;
  line-height: 1.5;
}

html:lang(ar) .home-wrapp .btn {
  padding: 0 30px 0 20px !important;
}

html:lang(ar) .home-wrapp .btn .icon {
  background-image: url('./Icons/arrow-backward.svg') !important;
}

html:lang(ar) .home-wrapp header .offcanvas .btn-close {
  margin-left: 0 !important;
}

html:lang(ar) .home-wrapp .main-banner {
  min-height: auto !important;
}

html:lang(ar) .home-wrapp .main-banner .content-text .lottiefile {
  left: 0 !important;
  right: auto !important;
}

html:lang(ar) .home-wrapp .main-banner .scroll-down {
  left: 0px !important;
  right: auto !important;
}

html:lang(ar) .home-wrapp .typrewriter .dropdown-typewriter .btn:after {
  background-image: url('./Icons/caret-down.svg');

  margin-right: 30px !important;
  margin-left: auto !important;
}

html:lang(ar) .home-wrapp .purpose .lottie-wrap .lottiefile {
  -webkit-transform: translate(50%, -50%) !important;
  transform: translate(50%, -50%) !important;
  right: 50% !important;
  width: 100%;
  left: 0 !important;
  margin-top: auto;
}

@media (min-width: 992px) {
  html:lang(ar) .home-wrapp .purpose .lottie-wrap .lottiefile {
    right: auto;
    left: 0 !important;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
  }
}

@media (min-width: 1400px) {
  html:lang(ar) .home-wrapp .purpose .lottie-wrap .lottiefile {
    max-width: 370px;
    right: 5% !important;
  }
}

@media (min-width: 992px) {
  html:lang(ar) .home-wrapp .name .lottie-wrap .lottiefile {
    -webkit-transform: translate(50%, -50%) !important;
    transform: translate(50%, -50%) !important;
  }
}

html:lang(ar) .home-wrapp .name .f-image .content-image img {
  height: 300px !important;
}

@media (min-width: 768px) {
  html:lang(ar) .home-wrapp .name .f-image .content-image img {
    position: absolute !important;
    width: 550px !important;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    left: 50%;
  }
}
