.summarize-wrap {
    direction: rtl;
}

.input-section {
    margin-top: 32px;
    padding: 0 60px 0;
    font-family: 'FS-Albert-Pro', sans-serif;
}

@media all and (max-width: 1023px) {
    .input-section {
        padding: 30px 0;
    }
}

.input-section .form-input textarea {
    min-height: 270px;
    border-radius: 10px;
    border: 1px solid #bfbfbf;
    padding: 30px 40px;
    font-size: 20px;
    color: #6f6f6f;
    resize: none;
}

@media all and (max-width: 1023px) {
    .input-section .form-input textarea {
        padding: 20px;
        font-size: 16px;
        min-height: 170px;
    }
}

.input-section .form-input textarea:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-section .form-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.input-section .form-action .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0;
    border: 0px none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.input-section .form-action .btn .text {
    color: white;
    font-size: 15px;
    font-weight: bold;
    font-family: 'FS-Albert-Pro', sans-serif;
}

.input-section .form-action .btn .icon-btn {
    margin-right: 30px;
}

.section-generate {
    background-color: white;
    padding: 30px 0;
    font-family: 'FS-Albert-Pro', sans-serif;
    min-height: 400px;
}

.section-generate h6 {
    color: #243746;
    opacity: 0.5;
    font-size: 18px;
    font-weight: normal;
}

@media all and (max-width: 1023px) {
    .section-generate h6 {
        font-size: 14px;
    }
}

.section-generate p {
    color: #243746;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
}

@media all and (max-width: 1023px) {
    .section-generate p {
        font-size: 17px;
        line-height: 26px;
    }
}
