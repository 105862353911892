.pages .navbar {
  background-color: #233746!important;
}

p, li {
  opacity: 1!important;
  text-align: justify;
}

a {
  color: #00b577;
  text-decoration: none;
}

table {
  margin-bottom: 20px;
}

td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

td:first-child {
  width: 20%;
}

thead td {
  font-weight: bold;
}

.policies {
  padding: 150px 0;
  position: relative;
}