.quizzer-container {
    width: 100%;
    margin-top: 32px;
    direction: rtl;
}

.question-box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000015, 0px 1px 1px #ffffff !important;
    border: 1px solid #24374639 !important;
    border-radius: 5px;
    font: normal normal bold 24px/25px FS Albert Pro;
    padding: 20px 10px 60px 10px;
    resize: none;
}

.option-box {
    background-color: #ffffff;
    border: 1px solid #d6d6d691 !important;
    box-shadow: none !important;
    border-radius: 5px;
}

.quiz-btn {
    box-shadow: 0px 4px 15px #00c08b4a;
    border-radius: 3px;
    height: 55px;
    max-width: 332px;
}

.answer-box {
    background-color: #f2f5f7;
    border: 1px solid #7070702b;
    border-radius: 8px;
    font: normal normal normal 18px/26px FS Albert Pro;
    color: #00c08b;
    height: 150px;
}

.subheader {
    font: normal normal bold 16px/19px FS Albert Pro;
    color: #243746;
    text-align: right;
}

.subheader:after {
    content: "";
    display: inline-block;
    border: 0.5px dashed #707070;
    opacity: 0.33;
    width: calc(100% - 100px);
    margin: 3px 15px;
}

@media (min-width: 992px) {
    .question-box {
        padding: 30px;
    }

    .subheader {
        text-align: center;
    }

    .subheader:before {
        content: "";
        display: inline-block;
        border: 0.5px dashed #707070;
        opacity: 0.33;
        width: calc(50% - 100px);
        margin: 3px 15px;
    }

    .subheader:after {
        width: calc(50% - 100px);
    }

    .quizzer-container {
        width: 50%;
    }
}
