.bubble {
    border-radius: 16px;
    padding: 7px 20px;
    font: normal 16px/20px FS Albert Pro;
    font-family: 'Tajawal', sans-serif !important;
    max-width: 100%;
    width: auto;
    display: block;
}

.noorbot {
    display: flex;
    align-items: center;
    margin-top: 20px;
    transform-origin: 100% 100%;
    animation: message 0.15s ease-out 0s forwards;
}

.noorbot + .noorbot {
    margin-top: 5px;
}

.noorbot .bubble {
    background: #f2f5f7;
    border-radius: 16px;
    display: block;
    font: normal 16px/20px FS Albert Pro;
    font-family: Tajawal, sans-serif !important;
    max-width: 100%;
    padding: 7px 20px;
    width: auto;
}

.user {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 20px;
    transform-origin: 0 100%;
    animation: message 0.15s ease-out 0s forwards;
}

.user .bubble {
    background-color: #00c08b;
    color: #fff;
    min-height: 35px;
    display: flex;
    align-items: center;
    padding: 8px 20px 5px 20px;
    text-align: left;
    border-radius: 16px;
}

.user + .user {
    margin-top: 5px;
}

.msgbox {
    background: #f2f5f7;
    border-radius: 0 30px 30px 0 !important;
    border: none;
    padding: 10px 15px;
}

.msgbox + .input-group-append .btn {
    background: #f2f5f7;
    border-radius: 30px 0px 0px 30px;
}

.chat {
    direction: rtl;
    margin-top: 32px;
    position: relative;
}

.chat *:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: #f2f5f7 !important;
}

#chatcontainer {
    display: block;
    height: calc(100vh - 400px);
    overflow-y: auto;
    overflow-x: hidden;
}

.msg-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
}

.img {
    display: inline-block;
}

@media (min-width: 992px) {
    .bubble {
        max-width: 40%;
    }
}

/* @keyframes message {
  0% {
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */
/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */

@media all and (max-width: 990px) {
    .container-chatbox::after,
    .container-chatbox::before {
        display: none;
    }
}

/* spinner */
/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
    margin: 0px auto 0;
    width: auto;
    text-align: center;
}

.spinner > div {
    width: 10px;
    height: 10px;
    background-color: #00c08b;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
