.temperature-button {
    --color: red;
    padding: 0 12px;
    max-width: 100px;
    border: 2px solid var(--color);
    color: var(--color) !important;
    background-color: transparent;
    line-height: 1 !important;
    height: 31px;
    flex: 1 1 auto;
}

.temperature-button.--selected {
    color: white !important;
    background-color: var(--color);
}

.temperature-button:focus {
    box-shadow: none !important;
}