.btn {
    border: 0;

    -webkit-transition: ease 0.3s;
    -webkit-transition-property: background-color, color;
    transition: ease 0.3s;
    transition-property: background-color, color;

    -ms-flex-pack: distribute;
    justify-content: space-around;
    max-width: 250px;
    width: 100%;
    background-color: #ffffff;
    padding: 0 30px;
    overflow: hidden;
    position: relative;

    color: white;
    font-size: 15px;
    font-weight: bold;
}

.btn:focus {
    box-shadow: 0 0 15px #00c08baa !important;
}

.btn:hover {
    color: white;
}

.green {
    background-color: #00c08b;
}

.green:hover {
    background-color: #243746;
}

.glow {
    box-shadow: 0 4px 15px #00c08b4a;
}