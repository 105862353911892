.carousel {
    background-color: #243746;
}

.option {
    --line-height: 12em;
    height: var(--line-height);
}

.select-option {
    background: none;
    outline: none;
    border: none;
}

.select-option > svg {
    filter: drop-shadow(0 4px 15px #00c08b4a);
    color: #00C08B;
    transition: color 0.3s ease;
}

.select-option:hover > svg {
    color: #243746;
}